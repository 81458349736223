import { TransactionAction, ColorType } from 'types';

type ActionItem = TransactionAction['actionItem'];

const ACCEPT: ActionItem = {
  name: 'accept',
  text: 'Accept',
};

const DISABLED_ACCEPT: ActionItem = {
  name: 'disabled_accept',
  text: 'Accept',
  disabled: true,
};

const CANCEL: ActionItem = {
  name: 'cancel',
  text: 'Cancel',
  color: ColorType.DANGER,
};

// always be enabled although the main button is disabled
const CANCEL_ENABLED: ActionItem = {
  name: 'cancel',
  text: 'Cancel',
  color: ColorType.DANGER,
  disabled: false,
};

const DELAY: ActionItem = {
  name: 'delay',
  text: 'Delay',
};

const DELIVER: ActionItem = {
  name: 'deliver',
  text: 'Deliver',
};

const EXPORT: ActionItem = {
  name: 'export',
  text: 'Export',
};

const EXPORT_FULL_ORDER_DETAILS: ActionItem = {
  name: 'export_full_order_details',
  text: 'Export Full Order Details',
};

const FULFILL: ActionItem = {
  name: 'fulfill',
  text: 'Fulfill',
};

const RETURN: ActionItem = {
  name: 'return',
  text: 'Return',
};

const MARK_AS_UNDELIVERED: ActionItem = {
  name: 'mark_as_undelivered',
  text: 'Mark as Undelivered',
};

const MERGE: ActionItem = {
  name: 'merge',
  text: 'Merge',
};

const APPROVE_PREKURSOR: ActionItem = {
  name: 'approve_prekursor',
  text: 'Approved',
};

const REJECT_PREKURSOR: ActionItem = {
  name: 'reject_prekursor',
  text: 'Reject',
  color: ColorType.DANGER,
};

const CANCEL_PREKURSOR: ActionItem = {
  name: 'cancel_prekursor',
  text: 'Cancel',
  color: ColorType.DANGER,
};

const PRINT: ActionItem = {
  name: 'print',
  text: 'Print PO',
};

const DISABLED_PRINT: ActionItem = {
  name: 'disabled_print',
  text: 'Print PO',
  disabled: true,
};

const PRINT_DELIVERY_DOCS: ActionItem = {
  name: 'print_delivery_docs',
  text: 'Print Delivery Docs',
};

const PUSH_ORDER: ActionItem = {
  name: 'push_order',
  text: 'Push to WMS & Netsuite',
  disabled: false,
}

const REASSIGN: ActionItem = {
  name: 'reassign',
  text: 'Re-assign',
};

const RESTORE: ActionItem = {
  name: 'restore',
  text: 'Restore',
};

const REPRINT: ActionItem = {
  name: 'reprint',
  text: 'Re-print PO',
};

const REPRINT_DELIVERY_DOCS: ActionItem = {
  name: 'reprint_delivery_docs',
  text: 'Re-print Delivery Docs',
};

const SEND_TO_DISPATCHER: ActionItem = {
  name: 'send_to_dispatcher',
  text: 'Send to Dispatcher',
};

const ROLLBACK: ActionItem = {
  name: 'rollback',
  text: 'Rollback',
  color: ColorType.DANGER,
};

const UPDATE_DELIVERY_DOCS: ActionItem = {
  name: 'update_delivery_docs',
  text: 'Update Delivery Docs',
};

/* const UPDATE_DELIVERY_STATUS: ActionItem = {
  name: 'update_delivery_status',
  text: 'Update Delivery Status',
};

const UPDATE_TRACKING_NUMBER: ActionItem = {
  name: 'update_tracking_number',
  text: 'Update Tracking Number',
}; */

const EXPORT_TO_DELIVERY_PARTNER: ActionItem = {
  name: 'export_to_delivery_partner',
  text: 'Export for Delivery Partner',
};

const PRINT_INVOICE_DOCS: ActionItem = {
  name: 'print_invoice_docs',
  text: 'Print Invoice Docs',
};

const REPRINT_INVOICE_DOCS: ActionItem = {
  name: 'reprint_invoice_docs',
  text: 'Re-Print Invoice Docs',
};

const PRINT_DELIVERY_PROOF: ActionItem = {
  name: 'print_delivery_proof',
  text: 'Print Delivery Proof',
};

const REPRINT_DELIVERY_PROOF: ActionItem = {
  name: 'reprint_delivery_proof',
  text: 'Re-print Delivery Proof',
};

const UPDATE_INVOICE_DATES: ActionItem = {
  name: 'update_invoice_dates',
  text: 'Update Invoice Dates',
};


const MANUAL_CREATE_AWB: ActionItem = {
  name: 'manual_create_awb',
  text: 'Manual Create AWB',
};

const MERGE_SHIPMENT: ActionItem = {
  name: 'merge_shipment',
  text: 'Merge Shipment',
};

const DELETE_SHIPMENT: ActionItem = {
  name: 'delete_shipment',
  text: 'Delete Shipment',
};

export const TRANSACTION_ACTIONS = {
  ACCEPT,
  DISABLED_ACCEPT,
  CANCEL,
  CANCEL_ENABLED,
  DELAY,
  DELIVER,
  EXPORT,
  EXPORT_FULL_ORDER_DETAILS,
  FULFILL,
  MARK_AS_UNDELIVERED,
  MERGE,
  PRINT_DELIVERY_DOCS,
  APPROVE_PREKURSOR,
  CANCEL_PREKURSOR,
  REJECT_PREKURSOR,
  PRINT,
  DISABLED_PRINT,
  PUSH_ORDER,
  REASSIGN,
  RESTORE,
  RETURN,
  REPRINT_DELIVERY_DOCS,
  REPRINT,
  SEND_TO_DISPATCHER,
  ROLLBACK,
  UPDATE_DELIVERY_DOCS,
  // UPDATE_TRACKING_NUMBER,
  // UPDATE_DELIVERY_STATUS,
  EXPORT_TO_DELIVERY_PARTNER,
  PRINT_INVOICE_DOCS,
  REPRINT_INVOICE_DOCS,
  PRINT_DELIVERY_PROOF,
  REPRINT_DELIVERY_PROOF,
  UPDATE_INVOICE_DATES,
  MANUAL_CREATE_AWB,
  MERGE_SHIPMENT,
  DELETE_SHIPMENT,
};
