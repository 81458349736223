import {
  IDeliveryPartner,
  Invoice,
  Order,
  OrderList,
  OrderListExportParams,
  OrderListParams,
  OrderParams,
  OrderInvoiceSummary,
  OrderInvoiceSummaryParams,
} from './interfaces';
import { InvoiceCreate, InvoiceUpdate } from './types';
import { SwipeRxPtOrderItems } from './SwipeRxPtOrderItems.class';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { AvailableDistributor } from '../organizations/interfaces';
import { OrderAmountDiff } from './types/OrderAmoutDiff.type';

export class SwipeRxPtOrders {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  readonly items: SwipeRxPtOrderItems;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.items = new SwipeRxPtOrderItems(base);
    this.resource = SwipeRxPtV3Resources.ORDERS;
  }

  cancelRequest(): void {
    this.base.cancelRequest();
  }

  async cancel(payload: { order_ids: number[]; reason: string; date: string }): Promise<void> {
    await this.base.post(`${this.resource}/cancel`, payload);
  }

  async return(payload: {
    order_id: number;
    return_type: string;
    reason: string;
    item_updates: { id: number; quantity: number; return_reason: string }[];
    date?: string;
  }): Promise<void> {
    await this.base.post(`${this.resource}/return`, payload);
  }

  async export(params?: OrderListExportParams): Promise<void> {
    const { order_ids } = params || {};

    await this.base.get(`${this.resource}/export`, {
      ...params,
      order_ids: order_ids && order_ids.join(','),
    });
  }

  async exportFullOrderDetails(params?: OrderListExportParams): Promise<void> {
    const { order_ids } = params || {};

    await this.base.get(`${this.resource}/export-full-order-details`, {
      ...params,
      order_ids: order_ids && order_ids.join(','),
    });
  }

  async list(params?: OrderListParams): Promise<OrderList> {
    const result = await this.base.get(`${this.resource}/`, params);

    return result;
  }

  async retrieve(id: number, params?: OrderParams): Promise<any> {
    const { data } = await this.base.get(`${this.resource}/${id}`, params);

    return data;
  }

  async merge(poNumbers: string[]): Promise<Order> {
    const { data } = await this.base.post(`${this.resource}/merge`, {
      po_numbers: poNumbers,
    });

    return data;
  }

  async splitOrderItems(poNumber: string, orderItemIds: number[]): Promise<[Order, Order]> {
    const { data } = await this.base.post(`${this.resource}/split`, {
      po_number: poNumber,
      item_ids: orderItemIds,
    });

    return data;
  }

  async approvePrekursor(orderIds: number[]): Promise<Order[]> {
    const { data } = await this.base.post(`${this.resource}/approve-prekursor`, {
      order_ids: orderIds,
    });

    return data;
  }

  async process(orderIds: number[]): Promise<Order[]> {
    const { data } = await this.base.post(`${this.resource}/process`, {
      order_ids: orderIds,
    });

    return data;
  }

  async accept(orderIds: number[]): Promise<Order[]> {
    const { data } = await this.base.post(`${this.resource}/accept`, {
      order_ids: orderIds,
    });

    return data;
  }

  async rollback(payload: { order_ids: number[]; reason: string }): Promise<Order[]> {
    const { data } = await this.base.post(`${this.resource}/rollback`, payload);
    return data;
  }

  async sendToDispatcher(orderIds: number[]): Promise<Order[]> {
    const { data } = await this.base.post(`${this.resource}/dispatch`, {
      order_ids: orderIds,
    });

    return data;
  }

  async restore(orderIds: number[]): Promise<Order[]> {
    const { data } = await this.base.post(`${this.resource}/restore`, {
      order_ids: orderIds,
    });

    return data;
  }

  async createInvoice(orderId: number, invoice: InvoiceCreate): Promise<Invoice> {
    const { data } = await this.base.post(`${this.resource}/${orderId}/invoice`, invoice);

    return data;
  }

  async updateInvoice(orderId: number, invoice: InvoiceUpdate): Promise<Invoice> {
    const { data } = await this.base.patch(`${this.resource}/${orderId}/invoice`, invoice);

    return data;
  }

  async updateInvoiceDates(payload: { order_ids: number[]; invoiced_at: string }): Promise<Invoice[]> {
    return this.base.post(`${this.resource}/update-order-invoices`, payload);
  }

  async pushOrder(orderId: number): Promise<void> {
    const { data } = await this.base.post(`${this.resource}/repush-message`, { orderId });

    return data;
  }

  // eslint-disable-next-line class-methods-use-this
  async reassign(payload: { distributor_id: number; order_ids: number[] }): Promise<void> {
    await this.base.post(`${this.resource}/reassign`, payload);
  }

  async getAvailableDistributorForReassignment(
    orderIds: number[],
  ): Promise<{ availableDistributors: AvailableDistributor[]; info: string[] }> {
    const { data } = await this.base.get(`${this.resource}/reassign/available-distributors`, {
      order_ids: orderIds,
    });
    return data;
  }

  async delay(payload: { order_ids: number[]; reason: string }): Promise<void> {
    await this.base.post(`${this.resource}/mark/delayed`, payload);
  }

  async markDeliveryDocsAsPrinted(orderIds: number[]): Promise<Order[]> {
    const { data } = await this.base.post(`${this.resource}/mark/delivery-docs-printed`, {
      order_ids: orderIds,
    });

    return data;
  }

  async markAsUndelivered(orderIds: number[]): Promise<Order[]> {
    const { data } = await this.base.post(`${this.resource}/mark/undelivered`, {
      order_ids: orderIds,
    });

    return data;
  }

  /* async updateTracking(
    orderIds: number[],
    number: string,
    pointId: string,
    logisticId: number | null,
    status: string,
    statusDetail?: string,
  ): Promise<void> {
    await this.base.patch(`${this.resource}/update-tracking-number`, {
      order_id: orderIds[0],
      tracking_number: number,
      point_id: pointId,
      logistics_delivery_partner_id: logisticId,
      status,
      status_detail: statusDetail,
    });
  } */

  async fulfill(payload: { order_id: number; invoice: string; ttf: string }): Promise<void> {
    await this.base.post(`${this.resource}/fulfill`, payload);
  }

  async deliver(orderIds: number[], date: string): Promise<Order[]> {
    const { data } = await this.base.post(`${this.resource}/deliver`, {
      order_ids: orderIds,
      date,
    });

    return data;
  }

  async updateDocuments(orderId: number, payload: { invoice: string; ttf: string }): Promise<{ data: string }> {
    return this.base.post(`${this.resource}/${orderId}/update-documents`, payload);
  }

  async patchOrder(
    orderId: number,
    payload: {
      ordered_at?: string;
    },
  ): Promise<{ data: string }> {
    const { data } = await this.base.patch(`${this.resource}/${orderId}`, payload);

    return data;
  }

  async getOrderItemReassignAvailableDistributors(
    orderId: number,
    orderItemIds: number[],
  ): Promise<{ id: number; name: string }[]> {
    const { data } = await this.base.get(
      `${this.resource}/${orderId}/items/reassign/distributors?item_ids=${orderItemIds.join(',')}`,
    );

    return data;
  }

  async reassignOrderItems(payload: {
    orderId: number;
    orderItemIds: number[];
    distributorId: number;
  }): Promise<Order> {
    const { orderId, orderItemIds, distributorId } = payload;
    const { data } = await this.base.post(`${this.resource}/${orderId}/items/reassign`, {
      item_ids: orderItemIds,
      distributor_id: distributorId,
    });

    return data;
  }

  async sendToDispatcherDeliveryPartner(
    payload: OrderListExportParams,
    thirdPartyLogisticId: number,
  ): Promise<Order[]> {
    const { order_ids, ...params } = payload;

    const { data } = await this.base.post(
      `${this.resource}/dispatch-delivery-partner`,
      {
        order_ids,
        logistics_delivery_partner_id: thirdPartyLogisticId,
      },
      { params },
    );

    return data;
  }

  async getDeliveryPartners(params: {
    term?: string;
    page_size?: number;
    order_by?: string;
    sort_by?: string;
  }): Promise<IDeliveryPartner[]> {
    const { data } = await this.base.get('logistics-delivery/partners', {
      page_size: 5,
      order_by: 'name',
      sort_by: 'asc',
      ...params,
    });
    return data;
  }

  async postManualShipment(params: { order_ids: number[] }): Promise<void> {
    await this.base.postV2('logistics-delivery/order/manual-shipment', params);
  }

  async postMergeShipment(params: { order_ids: number[] }): Promise<void> {
    await this.base.postV2('logistics-delivery/order/merge-shipment', params);
  }

  async postDeleteShipment(params: { order_ids: number[] }): Promise<void> {
    await this.base.postV2('logistics-delivery/order/delete-shipment', params);
  }

  /* async bulkTrackingNumber(
    file: File,
  ): Promise<{
    valid_rows: number;
    invalid_rows: number;
    errors: {
      row: number;
      errors: string[];
    }[];
  }> {
    const form = new FormData();
    form.append(
      'file',
      new Blob(
        [await file.arrayBuffer()],

        { type: 'text/csv' },
      ),
      file.name,
    );

    const { data } = await this.base.patch(`${this.resource}/bulk-tracking-number`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
    return data;
  } */

  async getTotalRowCount(params?: OrderListParams): Promise<any> {
    const { data } = await this.base.get(`${this.resource}/total-row-count`, params);

    return data;
  }

  async countAmountDiff(orderIds: number[]): Promise<OrderAmountDiff[]> {
    const { data } = await this.base.post(`${this.resource}/amount-diff`, {
      order_ids: orderIds,
    });

    return data;
  }

  async getOverLimitSummary(orderIds: number[]): Promise<OrderAmountDiff[]> {
    const { data } = await this.base.post(`${this.resource}/over-limit-summary`, {
      order_ids: orderIds,
    });

    return data;
  }

  async getOrderInvoiceSummary(pharmacyId: number, params: OrderInvoiceSummaryParams): Promise<OrderInvoiceSummary> {
    const result = await this.base.get(`${this.resource}/orders-invoices-summary/${pharmacyId}`, params);

    return result;
  }
}
